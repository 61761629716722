import React, {Suspense, lazy} from 'react'
import {useGlobalClassName} from 'utils/useGlobalClassName'
import PageFallback from 'components/Page/Fallback'

const Stat = lazy(
  () =>
    import(
      /* webpackChunkName: "Stat" */
      './'
    )
)

const LazyStat: React.FC = () => {
  useGlobalClassName('redesigned-body')

  return (
    <Suspense fallback={<PageFallback />}>
      <Stat />
    </Suspense>
  )
}

export default LazyStat
