const getNumberFormat =
  (options: Intl.NumberFormatOptions) => (value: number) =>
    value.toLocaleString('ru-RU', options)

export const numberFormat = getNumberFormat({
  maximumFractionDigits: 2
})

export const fractionNumberFormat = getNumberFormat({
  maximumFractionDigits: 2,
  minimumFractionDigits: 2
})

export const numberLocalFormat = new Intl.NumberFormat('ru-RU', {
  notation: 'compact',
  compactDisplay: 'short'
})

const getNumberString = (value: number, minDigitsCount = 2): string => {
  let result = String(value)

  while (result.length < minDigitsCount) {
    result = `0${result}`
  }

  return result
}

export const getTimeDuration = (
  secondsValue: number,
  minutesDigitsCount = 2,
  hoursDigitsCount?: number // `auto` by default
): string => {
  const hours = Math.floor(secondsValue / 3600)
  const minutes = Math.floor((secondsValue % 3600) / 60)
  const seconds = Math.floor(secondsValue % 60)

  const hoursString =
    hoursDigitsCount != null
      ? getNumberString(hours, hoursDigitsCount)
      : hours || null
  const minutesString = getNumberString(
    minutes,
    hoursString ? 2 : minutesDigitsCount
  )
  const secondsString = getNumberString(seconds)

  return [hoursString, minutesString, secondsString]
    .filter((i) => i != null)
    .join(':')
}

export const getMsTimeDuration = (secondsValue: number): string => {
  const miliseconds = Math.floor((secondsValue % 1) * 1e3)
  const seconds = Math.floor(secondsValue % 60)
  const minutes = Math.floor(secondsValue / 60)
  const hours = Math.floor(secondsValue / 60 / 60)

  return [
    getNumberString(hours),
    getNumberString(minutes),
    getNumberString(seconds),
    getNumberString(miliseconds, 3)
  ].join(':')
}

export const formatBits = (bits: number, decimals = 2): string => {
  if (bits === 0) return '0 Bits'
  // TODO rename variables
  const k = 1000
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bits', 'Kbits', 'Mbits', 'Gbits']
  const i = Math.floor(Math.log(bits) / Math.log(k))

  return parseFloat((bits / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const getTimezone = (hoursOffset: number): string =>
  `${hoursOffset < 0 ? '−' : '+'}${getNumberString(
    Math.floor(hoursOffset)
  )}${getNumberString(Math.floor((hoursOffset % 1) * 60))}`
