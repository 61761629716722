import React from 'react'
import classnames from 'classnames'
import {Scrollbar, type ScrollbarProps} from 'react-scrollbars-custom'
import styles from './styles.css'

const STYLES = {
  position: undefined,
  top: undefined,
  right: undefined,
  bottom: undefined,
  left: undefined,
  display: undefined,
  width: undefined,
  height: undefined,
  marginTop: undefined,
  marginRight: undefined,
  marginBottom: undefined,
  marginLeft: undefined,
  paddingTop: undefined,
  paddingRight: undefined,
  paddingBottom: undefined,
  paddingLeft: undefined,
  borderRadius: undefined,
  background: undefined
}

type Props = Pick<
  ScrollbarProps,
  | 'className'
  | 'style'
  | 'children'
  | 'noScrollX'
  | 'noScrollY'
  | 'translateContentSizeYToHolder'
  | 'onUpdate'
> & {
  trackXClassName?: string
  forwardRef?: React.RefObject<typeof Scrollbar>
}

const CustomScrollbar: React.FC<Props> = ({
  className,
  trackXClassName,
  forwardRef,
  onUpdate,
  ...props
}) => (
  <Scrollbar
    onUpdate={onUpdate}
    ref={forwardRef}
    renderer={({elementRef, style, ...props}) => (
      <div
        {...props}
        className={classnames(className, styles.root)}
        ref={elementRef}
        style={{...style, ...STYLES}}
      />
    )}
    contentProps={{
      renderer: ({elementRef, style, ...props}) => (
        <div
          {...props}
          ref={elementRef}
          className={styles.content}
          style={{...style, ...STYLES}}
        />
      )
    }}
    wrapperProps={{
      renderer: ({elementRef, style, ...props}) => (
        <div
          {...props}
          ref={elementRef}
          className={styles.scrollbar}
          style={{...style, ...STYLES}}
        />
      )
    }}
    scrollerProps={{
      renderer: ({elementRef, style, ...props}) => (
        <div
          {...props}
          ref={elementRef}
          className={styles.inner}
          style={{...style, ...STYLES}}
        />
      )
    }}
    trackYProps={{
      renderer: ({elementRef, style, ...props}) => (
        <div
          {...props}
          ref={elementRef}
          className={styles.yTrack}
          style={{...style, ...STYLES}}
        />
      )
    }}
    thumbYProps={{
      renderer: ({elementRef, style, ...props}) => (
        <div
          {...props}
          ref={elementRef}
          className={styles.yThumb}
          style={{...style, ...STYLES}}
        />
      )
    }}
    trackXProps={{
      renderer: ({elementRef, style, ...props}) => (
        <div
          {...props}
          ref={elementRef}
          className={classnames(trackXClassName, styles.xTrack)}
          style={{...style, ...STYLES}}
        />
      )
    }}
    thumbXProps={{
      renderer: ({elementRef, style, ...props}) => (
        <div
          {...props}
          ref={elementRef}
          className={styles.xThumb}
          style={{...style, ...STYLES}}
        />
      )
    }}
    {...props}
  />
)

export default CustomScrollbar
