import dateformat from 'dateformat'

const today = new Date()
const todayStr = today.toDateString()

export const todayYear = today.getFullYear()

const yesterday = new Date()

yesterday.setDate(yesterday.getDate() - 1)

const yesterdayStr = yesterday.toDateString()

// вчера в 00:00 | cегодня в 00:00 | 2021-09-30 в 00:00
export const formatDate = (date: Date, readable = false): string => {
  let dateFormatStr = 'dd.mm.yyyy'

  if (readable) {
    const dateStr = date.toDateString()
    if (dateStr === todayStr) dateFormatStr = 'cегодня'
    else if (dateStr === yesterdayStr) dateFormatStr = 'вчера'
  }

  return dateformat(date, `${dateFormatStr} в HH:MM`)
}

// 00:00:00 | 2021-09-30 00:00:00
export const simpleFormatDate = (date: Date, short = false): string => {
  const formatStr =
    short && date.toDateString() === todayStr
      ? 'HH:MM:ss'
      : 'yyyy-mm-dd HH:MM:ss'

  return dateformat(date, formatStr)
}

// dateStr: ?DD.MM.YYYY HH:MM:SS
export const prepareDate = (
  dateStr: string | null | undefined
): void | string => {
  if (dateStr) {
    const [day, month, year, hour, minute] = dateStr.split(/[^\d]/).map(Number)

    return dateformat(
      new Date(year, month - 1, day, hour, minute),
      'isoDateTime'
    )
  }
}

// 30 сентября
export const shortDateString = new Intl.DateTimeFormat('ru', {
  day: 'numeric',
  month: 'long'
})

// 30 сентября 2021 г.
export const dateString = new Intl.DateTimeFormat('ru', {
  day: 'numeric',
  month: 'long',
  year: 'numeric'
})

// 30 сентября | 30 сентября 2020 г.
export const getDateString = (date: Date): string =>
  todayYear === date.getFullYear()
    ? shortDateString.format(date)
    : dateString.format(date)

export const getValidDate = (
  dateStr: string // dd.mm.yyyy
): void | string => {
  // yyyy-mm-dd
  try {
    const date = new Date(dateStr.split('.').reverse().join('-'))
    const dateStrFragments: number[] = dateStr
      .split('.')
      .map((item) => parseInt(item))
    const year = date.getFullYear()
    if (
      dateStrFragments.every(Boolean) &&
      year === dateStrFragments[2] &&
      year > 999 &&
      year < 1e4 &&
      date.getMonth() + 1 === dateStrFragments[1] &&
      date.getDate() === dateStrFragments[0]
    )
      return dateformat(date, 'yyyy-mm-dd')
  } catch (err: any) {} // eslint-disable-line no-empty
}

export const getDaysCount = (
  dateFrom: Date | number | string,
  dateTo: Date | number | string
): number => {
  const ts = [dateFrom, dateTo].map((item) => {
    const date = new Date(item)

    date.setHours(0, 0, 0, 0)

    return date.getTime()
  })

  return Math.abs((ts[1] - ts[0]) / 1e3 / 60 / 60 / 24) + 1
}

// 2021-09-30T00:00:00+03:00
export const toIsoDateTime = (date: Date): string => {
  const str = dateformat(date, 'isoDateTime')

  return `${str.slice(0, -2)}:${str.slice(-2)}`
}

export const getLastWeekRange = (
  dateFrom: Date | number | string = Date.now()
): [Date, Date] => {
  const prevWeekStart = new Date(dateFrom)
  const prevWeekEnd = new Date(dateFrom)
  const millisecondsInDay = 24 * 60 * 60 * 1e3

  prevWeekEnd.setDate(prevWeekEnd.getDate() - (prevWeekEnd.getDay() || 7))
  prevWeekStart.setTime(prevWeekEnd.getTime() - millisecondsInDay * 6)

  return [prevWeekStart, prevWeekEnd]
}

export const getLastMonthRange = (
  dateFrom: Date | number | string = Date.now()
): [Date, Date] => {
  const prevMonthStart = new Date(dateFrom)
  const prevMonthEnd = new Date(dateFrom)

  prevMonthStart.setDate(0)
  prevMonthStart.setDate(1)
  prevMonthEnd.setDate(0)

  return [prevMonthStart, prevMonthEnd]
}

export const addDaysToDate = (
  dateFrom: Date | number | string,
  daysCount: number
): Date => {
  const date = new Date(dateFrom)

  date.setDate(date.getDate() + daysCount)

  return date
}

export const addMonthsToDate = (
  dateFrom: Date | number | string,
  monthsCount: number
): Date => {
  const date = new Date(dateFrom)

  date.setMonth(date.getMonth() + monthsCount)

  return date
}

export const getLocalTimestamp = (
  date: Date | number | string,
  timezone: number
): number => {
  date = new Date(date)

  return (
    date.getTime() + date.getTimezoneOffset() * 60e3 + timezone * 60 * 60 * 1e3
  )
}
