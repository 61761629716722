import ApiError from 'utils/ApiError'
import type {PartNumbers} from 'transport/v3/uploader'
import {apiOrigin} from 'utils/env'
import {isObject} from 'utils/checkType'
import type {State, Item} from './context'

export const getEmptyFile = (name: string): File =>
  new File([new Blob([])], name)

export const getUpdatedData = (
  {data}: State,
  itemId: string,
  payload: Partial<Item>
): State['data'] => ({
  ...data,
  [itemId]: {
    ...data[itemId],
    ...payload
  }
})

export const ACCEPTED_TYPES: string[] = [
  '3g2',
  '3gp',
  'asf',
  'avi',
  'x-msvideo', // avi in safari
  'dif',
  'dv',
  'flv',
  'f4v',
  'm4v',
  'x-m4v', // m4v in safari
  'mov',
  'mp4',
  'mpeg',
  'mpg',
  'mts',
  'm2t',
  'm2ts',
  'qt',
  'wmv',
  'vob',
  'mkv',
  'x-matroska', // mkv in windows
  'ogv',
  'webm',
  'ogg',
  'mxf',
  'quicktime',
  'x-ms-wmv',
  'mpeg-tts'
]

export const INPUT_ACCEPTED_TYPES = `.${ACCEPTED_TYPES.join(',.')}`

export const sendFile = ({
  uploadId,
  file,
  partNumbers,
  onProgress,
  onChunkLoaded,
  signal
}: {
  uploadId: string
  file: File
  partNumbers?: PartNumbers
  onProgress: (progress: number) => any
  onChunkLoaded: (partNumbers: PartNumbers) => any
  signal?: AbortSignal
}): Promise<PartNumbers> => {
  const chunkSize = 5 * 1024 * 1024
  const outputPartNumbers = partNumbers?.slice() ?? []
  const initialPartNumber = outputPartNumbers.length + 1
  const xhr = new XMLHttpRequest()

  xhr.responseType = 'json'
  xhr.withCredentials = false
  if (signal) signal.addEventListener('abort', () => xhr.abort())

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (onLoaded, onRejected) => {
    const {size: fileSize} = file
    const chunksCount = Math.ceil(fileSize / chunkSize)
    let partNumber = initialPartNumber

    while (partNumber <= chunksCount) {
      const chunkStart = (partNumber - 1) * chunkSize
      const chunkEnd = Math.min(chunkStart + chunkSize, fileSize)

      await new Promise((resolve) => {
        xhr.onload = () => {
          const json = xhr.response
          let errorText
          let status

          if (xhr.status !== 200) {
            status = xhr.status
            errorText =
              xhr.statusText ||
              'API не отвечает. Обновите страницу и попробуйте ещё раз'
          } else if (json && json.status && json.errors) {
            const errors = json.errors.filter(
              (error: any) =>
                error && !(isObject(error) && Object.keys(error).length === 0)
            )

            if (errors.length > 0) {
              status = json.status
              errorText = errors.join(',')
            }
          }

          if (status && errorText) {
            onRejected(
              new ApiError(status, errorText, {
                chunkStart
              })
            )
          } else {
            outputPartNumbers.push({
              partNumber,
              entityTag: json.entityTag
            })
            resolve(null)
          }
        }

        xhr.onerror = () => {
          onRejected(
            new ApiError(
              0,
              'Ошибка запроса. Обновите страницу и попробуйте ещё раз',
              {
                chunkStart
              }
            )
          )
        }

        xhr.onabort = () =>
          onRejected(
            new DOMException('The user aborted a file uploading.', 'AbortError')
          )

        xhr.upload.onprogress = (event) => {
          if (event.total)
            onProgress(
              (chunkStart +
                ((chunkEnd - chunkStart) * event.loaded) / event.total) /
                fileSize
            )
        }

        xhr.open(
          'POST',
          `${apiOrigin}/api/uploader/upload/${uploadId}/${partNumber}`,
          true
        )
        xhr.send(file.slice(chunkStart, chunkEnd))
      })
      onChunkLoaded(outputPartNumbers.slice())
      partNumber += 1
    }

    onLoaded(outputPartNumbers)
  })
}
