import type {Account} from 'types/Me'

export const processItem = <
  Account extends {
    createdDate: string
    lastRecordDate: string | null | undefined
    [key: string]: any
  }
>({
  createdDate,
  lastRecordDate,
  ...account
}: Account): Account & {
  createdDate: Date
  lastRecordDate: Date | null | undefined
} => ({
  ...account,
  createdDate: new Date(createdDate),
  lastRecordDate:
    lastRecordDate == null ? lastRecordDate : new Date(lastRecordDate)
})

export type RamblerAccount = Account & {
  code: string
}

export const RAMBLER_ACCOUNTS: RamblerAccount[] = [
  {name: 'Еда.ру', id: 4548, code: 'eda'},
  {name: 'Афиша Daily', id: 10132, code: 'afishadaily'},
  {name: 'Letidor', id: 9851, code: 'letidor'},
  {name: 'Passion.ru', id: 9850, code: 'passionru'},
  {name: 'WMJ.ru', id: 9836, code: 'wmj'},
  {name: 'Ferra.ru', id: 9668, code: 'ferra'},
  {name: 'Авторамблер', id: 9629, code: 'autorambler'},
  {name: 'Motor', id: 8628, code: 'motor'},
  {name: 'Rvtest', id: 7774, code: 'rvtest'},
  {name: 'Рамблер/Видео', id: 7773, code: 'rv'},
  {name: 'Confluence', id: 5906, code: 'confluence'},
  {name: 'Quto', id: 5722, code: 'quto'},
  {name: 'Рамблер/Медиа', id: 5718, code: 'ramblernews'},
  {name: 'Афиша', id: 5576, code: 'afisha'},
  {name: 'Lenta.ru', id: 4912, code: 'lentaru'},
  {name: 'Gazeta.ru', id: 4253, code: 'gazeta'},
  {name: 'Живой Журнал', id: 4033, code: 'lj'},
  {name: 'Чемпионат', id: 3781, code: 'championat'},
  {name: 'EPL', id: 10874, code: 'epl'},
  {name: 'Олимп', id: 10875, code: 'olimp'},
  {name: 'Индикатор', id: 10871, code: 'indicator'},
  {name: 'Секрет Фирмы', id: 10873, code: 'secretmag'},
  {name: 'Мослента', id: 11006, code: 'moslenta'},
  {name: 'HMD', id: 10999, code: 'hmd'},
  {name: 'Рамблер/почта', id: 10924, code: 'ramblermail'},
  {name: 'Афиша Рестораны', id: 10821, code: 'afisharest'},
  {name: 'Rambler.Kassa', id: 10044, code: 'ramblerkassa'},
  {name: 'Rambler / Education', id: 11045, code: 'education'}
]

export const isRamblerAccount = (accountCode: string): boolean =>
  RAMBLER_ACCOUNTS.some((account) => account.code === accountCode)
