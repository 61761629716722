import React, {Component, Fragment} from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'
import EventEmitter from 'eventemitter3'
import Button from 'rambler-ui/Button'
import {providePopup} from 'rambler-ui/Popup'
import Spinner from 'rambler-ui/Spinner'
import {activateMetrics, hit} from 'utils/metrics'
import {
  getRamblerIdProfileInfo,
  openRamblerIdLogin,
  addListener,
  removeListener,
  logout
} from 'utils/ramblerIdHelper'
import {hasUserVisited, saveUserVisit} from 'utils/userVisits'
import {getPermissionInfo} from 'transport/v3/permissions'
import withRouter, {type WithRouterProps} from 'hoc/withRouter'
import {
  withConfirmation,
  type WithConfirmationProps
} from 'components/ConfirmationProvider'
import {snackbarEvent} from 'components/rc/snackbar'
import LayoutProvider from 'containers/LayoutProvider'
import Topline from 'containers/Topline'
import Footer from 'components/Footer'
import NoAccessPage from 'components/Page/NoAccess'
import WelcomePopup from 'components/WelcomePopup'
import Account from 'containers/Account'
import UploadRecordsProvider from 'containers/UploadRecordsProvider'
import PollingRecordsProvider from 'containers/PollingRecordsProvider'
import CommandCenter from 'containers/CommandCenter/Lazy'
import CopyrightHoldersDashboardStat from 'containers/CopyrightHoldersDashboardStat/Lazy'
import CopyrightHoldersDashboard from 'containers/CopyrightHoldersDashboard/Lazy'
import Analytics from 'containers/Analytics/Lazy'
import AppContext, {
  type State,
  type ContextType,
  type SnackbarProps,
  DEFAULT_USER,
  DEFAULT_PERMISSIONS,
  DEFAULT_STATE
} from './context'
import {setDefaultLabels} from 'utils/prometheus'
import {preparePermissions, getLocationPath} from './utils'
import ContentButton from 'components/ContentButton'
import styles from './styles.css'
import common from 'styles/common.css'

type Props = WithRouterProps & WithConfirmationProps

class App extends Component<Props, State> {
  static getDerivedStateFromProps(
    props: Props,
    state: State
  ): Partial<State> | null {
    const [firstSegment, secondSegment] = props.location.pathname.split('/ac/')
    let accountCode: State['accountCode'] = null

    if (!firstSegment && secondSegment) {
      const [accountCodeSegment] = secondSegment.split('/')
      if (accountCodeSegment) accountCode = accountCodeSegment
    }

    return accountCode === state.accountCode ? null : {accountCode}
  }

  state: State = DEFAULT_STATE
  emitter: EventEmitter = new EventEmitter()

  componentDidMount(): void {
    activateMetrics()
    setDefaultLabels({account: String(this.state.accountCode)})
    hit(getLocationPath(this.props.location))
    addListener('logout', openRamblerIdLogin)
    this.fetchData()
  }

  componentDidUpdate(prevProps: Props, prevState: State): void {
    const pathname = getLocationPath(this.props.location)
    const prevPathname = getLocationPath(prevProps.location)

    if (pathname !== prevPathname) hit(pathname)

    if (prevState.accountCode !== this.state.accountCode) {
      setDefaultLabels({account: String(this.state.accountCode)})
    }
  }

  componentWillUnmount(): void {
    this.emitter.removeAllListeners()
    removeListener('logout', openRamblerIdLogin)
  }

  getAccountPermissions: ContextType['getAccountPermissions'] = () => {
    const {permissions, accountCode} = this.state

    return (
      (accountCode && permissions && permissions[accountCode]) ||
      DEFAULT_PERMISSIONS
    )
  }

  getPath: ContextType['getPath'] = (
    path,
    accountCode = this.state.accountCode || undefined
  ) =>
    accountCode ? `/ac/${accountCode}${path ? '/' : ''}${path}` : `/${path}`

  copyValue: ContextType['copyValue'] = async (value, message) => {
    await navigator.clipboard.writeText(String(value))
    if (message) this.openSnackbar({message})
  }

  openSnackbar: ContextType['openSnackbar'] = ({
    actionButton,
    onAction,
    align = 'top center',
    autoCloseDuration = 4e3,
    withCloseButton = false,
    ...params
  }) => {
    snackbarEvent({
      ...params,
      autoCloseDuration,
      withCloseButton,
      align,
      actionButtons: actionButton
        ? [
            {
              title: actionButton,
              onClick: onAction
            }
          ]
        : undefined
    })
  }

  handleException: ContextType['handleException'] = (err) => {
    const {params} = err
    const copyMessage = [err.message || 'Что-то пошло не так']
    const snackbarMessage = copyMessage.slice()

    const props: SnackbarProps = {
      type: 'error',
      message: ''
    }

    if (params && params.requestId) {
      snackbarMessage.push(params.endpoint)
      copyMessage.push(
        `Идентификатор запроса: ${params.requestId}`,
        `Внутренний идентификатор запроса: ${params.internalRequestId}`,
        `Метод: ${params.endpoint}`
      )
      props.actionButton = 'Скопировать'
      props.onAction = () =>
        this.copyValue(copyMessage.join('\n'), 'Текст ошибки скопирован')
    }

    props.multiline = snackbarMessage.length > 1
    props.message = snackbarMessage
      .map((item) => `<div class="${common.errorText}">${item}</div>`)
      .join('')

    this.openSnackbar(props)
  }

  async fetchData(): Promise<void> {
    this.setState({
      isFetching: true
    })

    const state: Partial<State> = {
      isFetching: false
    }

    try {
      const profile = await getRamblerIdProfileInfo({
        get_accounts: 1
      })

      if (profile) {
        const avatar: string = profile.display.avatar.url

        if (
          profile.info.raw_accounts &&
          Array.isArray(profile.info.raw_accounts.accounts) &&
          profile.info.raw_accounts.accounts.some(
            (item) => item.account_type === 'UNCONFIRMED_MAIL'
          )
        ) {
          state.user = {
            ...DEFAULT_USER,
            email: profile.info.email || null,
            avatar,
            confirmationNeeded: true
          }
          state.permissions = {}
          state.copyrightHolderPermissions = []
        } else {
          const response = await getPermissionInfo().catch((err) => {
            if (!err.params || err.params.type !== 'NOT_FOUND') throw err
          })

          if (response) {
            const {
              result: {user, admin, permissions}
            } = response

            state.user = {...user, admin, avatar}
            state.permissions = preparePermissions(permissions, admin)
            state.accountsList = permissions.map((item) => item.accountCode)
            state.accountCodes = Object.fromEntries(
              permissions.map((item) => [item.accountId, item.accountCode])
            )

            if (!hasUserVisited(user.id)) {
              ;(this.props as any)
                .openPopup((resolve: any, reject: any) => (
                  <WelcomePopup onSuccess={resolve} onClose={reject} />
                ))
                .closed.finally(() => saveUserVisit(user.id))
            }
          } else {
            state.user = {
              ...DEFAULT_USER,
              email: profile.info.email || null,
              avatar
            }
            state.permissions = {}
          }

          state.copyrightHolderPermissions = state.user.admin ? ['*'] : []
        }
      } else {
        openRamblerIdLogin()
      }
    } catch (err: any) {
      this.handleException(err)
      throw err
    } finally {
      this.setState(state as State)
    }
  }

  render(): JSX.Element {
    const {user, permissions, copyrightHolderPermissions, ...state} = this.state

    if (!user || !permissions || !copyrightHolderPermissions) return <Spinner />

    const hasAnalyticsPermissions =
      Object.values(permissions || {}).filter(
        (item) => item.permissions.media.can_view_accounts_statistics
      ).length > 1
    const isAdmin = user.admin
    const hasHolderPermissions = copyrightHolderPermissions.length > 0

    if (!hasHolderPermissions && !state.accountsList.length) {
      return (
        <Fragment>
          <Topline hideUser hideContacts />
          <Routes>
            <Route
              caseSensitive
              path="/"
              element={
                <NoAccessPage
                  title={
                    user.confirmationNeeded
                      ? 'Подтвердите почтовый адрес'
                      : undefined
                  }
                  message={
                    user.confirmationNeeded ? (
                      'Вы все еще не подтвердили почтовый адрес, указанный при регистрации в Rambler&Co ID'
                    ) : (
                      <Fragment>
                        Поздравляем, вы нашли тайную дверь. Но дальше вы пройти
                        не сможете.
                        <br />
                        Попробуйте{' '}
                        <ContentButton onClick={logout}>
                          выйти из системы
                        </ContentButton>{' '}
                        и начать заново
                      </Fragment>
                    )
                  }
                  control={
                    <Button size="small" onClick={logout}>
                      Выйти из системы
                    </Button>
                  }
                />
              }
            />

            <Route
              caseSensitive
              path="/*"
              element={<Navigate to="/" replace />}
            />
          </Routes>
        </Fragment>
      )
    }

    return (
      <AppContext.Provider
        value={{
          ...state,
          user,
          permissions,
          copyrightHolderPermissions,
          openSnackbar: this.openSnackbar,
          handleException: this.handleException,
          getConfirmation: this.props.getConfirmation,
          copyValue: this.copyValue,
          emitter: this.emitter,
          getAccountPermissions: this.getAccountPermissions,
          getPath: this.getPath
        }}>
        <PollingRecordsProvider>
          <UploadRecordsProvider>
            <LayoutProvider>
              <Topline />

              <Routes>
                {hasAnalyticsPermissions ? (
                  <Route
                    caseSensitive
                    path="/analytics/*"
                    Component={Analytics}
                  />
                ) : (
                  <Route
                    caseSensitive
                    path="/analytics"
                    Component={NoAccessPage}
                  />
                )}

                {!hasAnalyticsPermissions && (
                  <Route
                    caseSensitive
                    path="/analytics/*"
                    element={<Navigate to="/analytics" replace />}
                  />
                )}

                {isAdmin ? (
                  <Route caseSensitive path="/cc/*" Component={CommandCenter} />
                ) : (
                  <Route caseSensitive path="/cc" Component={NoAccessPage} />
                )}

                {!isAdmin && (
                  <Route
                    caseSensitive
                    path="/cc/*"
                    element={<Navigate to="/cc" replace />}
                  />
                )}

                {hasHolderPermissions ? (
                  <Route
                    caseSensitive
                    path="/kpo/-/:name/*"
                    Component={CopyrightHoldersDashboardStat}
                  />
                ) : (
                  <Route
                    caseSensitive
                    path="/kpo/-/:name"
                    Component={NoAccessPage}
                  />
                )}

                {!hasHolderPermissions && (
                  <Route
                    path="/kpo/-/:name/*"
                    element={<Navigate to="/kpo/-/:name" replace />}
                  />
                )}

                {isAdmin ? (
                  <Route
                    caseSensitive
                    path="/kpo/*"
                    Component={CopyrightHoldersDashboard}
                  />
                ) : (
                  <Route caseSensitive path="/kpo" Component={NoAccessPage} />
                )}

                {!isAdmin && (
                  <Route
                    caseSensitive
                    path="kpo/*"
                    element={<Navigate to="/kpo" replace />}
                  />
                )}

                <Route
                  caseSensitive
                  path="/ac/:account/*"
                  Component={Account}
                />
              </Routes>
            </LayoutProvider>
            <Footer className={styles.footer} />
          </UploadRecordsProvider>
        </PollingRecordsProvider>
      </AppContext.Provider>
    )
  }
}

export default providePopup(withConfirmation(withRouter(App)))
