import React, {useEffect, useState, useContext, Fragment} from 'react'
import {Routes, Route, Navigate, useParams, useLocation} from 'react-router-dom'
import Button from 'rambler-ui/Button'
import Spinner from 'rambler-ui/Spinner'
import {isRamblerAccount, RAMBLER_ACCOUNTS} from 'utils/account'
import {saveAccountVisit} from 'utils/accountsVisits'
import {getMe} from 'transport/users'
import {getAccountByCode} from 'transport/account'
import {getEncryptorStatusByCode} from 'transport/v3/accounts'
import NoAccessPage from 'components/Page/NoAccess'
import ContentMessage from 'components/ContentMessage'
import Page from 'components/Page'
import PageMessage from 'components/PageMessage'
import NotFound from 'components/images/NotFound'
import NoAccess from 'components/images/NoAccess'
import Records from 'containers/Records/Lazy'
import Livestreams from 'containers/Livestreams/Lazy'
import Stat from 'containers/Stat/Lazy'
import Settings from 'containers/Settings'
import SettingsRoutes from 'containers/Settings/SettingsRoutesLazy'
import UsersRoutes from 'containers/Settings/UsersRoutesLazy'
import AppContext from 'containers/App/context'
import AccountContext, {DEFAULT_STATE, type State} from './context'
import {DEFAULT_PERMISSIONS} from 'containers/AccountsPermissions'

const STATUS_HEADINGS: Map<number, string> = new Map([
  [404, 'Аккаунт не найден']
])

const STATUS_MESSAGES: Map<number, string> = new Map([
  [404, 'Нет такого аккаунта'],
  [401, 'Аккаунт заблокирован']
])

const Account: React.FC = () => {
  const {getPath} = useContext(AppContext)
  const [state, setState] = useState<State>(DEFAULT_STATE)
  const context = useContext(AppContext)
  const {accountCode, accountId, permissions, availableSections} =
    context.getAccountPermissions()

  const {account} = useParams()
  const {pathname} = useLocation()

  useEffect(() => {
    if (!accountCode || !accountId) return

    const fetchData = async (): Promise<void> => {
      setState((state) => ({...state, ...DEFAULT_STATE, isFetching: true}))

      const newState: Partial<State> = {
        isFetching: false
      }

      try {
        try {
          // to get api config endpoints
          const {data} = await getAccountByCode(accountCode)

          // to get csrf token and messenger code
          await getMe()

          let useEncryptor

          try {
            const response = await getEncryptorStatusByCode(accountCode)

            useEncryptor = response.result.useEncryptor
          } catch (err: any) {
            context.handleException(err)
            console.error(err)
          }

          newState.availableSections =
            data.account.available_sections ?? DEFAULT_PERMISSIONS
          newState.useEncryptor = useEncryptor ?? null
          newState.accountsForSharing = {
            list:
              isRamblerAccount(accountCode) &&
              availableSections.includes('SHARING')
                ? RAMBLER_ACCOUNTS.filter((item) => item.id !== accountId).map(
                    (item) => item.id
                  )
                : [],
            data: Object.fromEntries(
              RAMBLER_ACCOUNTS.map((item) => [item.id, item])
            )
          }

          newState.createdAt = new Date(data.account.created_at)
          newState.filterIdsRorRemoveVideos = Object.fromEntries(
            data.account.filter_ids_for_remove_videos.map((id) => [id, true])
          )

          const useUuidAfter = data.account.require_record_uuid_after

          newState.useRecordsUuid =
            !!useUuidAfter && Number(new Date(useUuidAfter)) <= Date.now()
          saveAccountVisit(accountCode)
        } catch (err: any) {
          if (err.code === 404 || err.code === 401) newState.status = err.code
          else if (err.message === 'Access Forbidden') newState.status = 403
          else throw err
        }

        newState.isReady = true
      } catch (err: any) {
        context.handleException(err)
        throw err
      } finally {
        setState((state) => ({...state, ...newState}))
        if (newState.status)
          context.emitter.emit('accountFetchingError', {
            accountCode,
            status: newState.status
          })
      }
    }

    fetchData()
  }, [accountCode, accountId])

  const {isReady, status} = state

  if (isReady) {
    if (status) {
      const accountPathname = getPath('', account)

      return (
        <Fragment>
          <Page>
            <ContentMessage
              heading={STATUS_HEADINGS.get(status) ?? 'Доступ запрещен'}
              message={STATUS_MESSAGES.get(status) ?? 'Нет доступа к аккаунту'}
              icon={status === 404 ? <NotFound /> : <NoAccess />}
            />
          </Page>

          {pathname !== accountPathname && <Navigate to={accountPathname} />}
        </Fragment>
      )
    }

    const canViewStat = !!permissions.yauth?.can_view_account_stat
    const canUseStreaming = !!permissions.streaming?.can_use_translations
    const canAccountUseStreaming = availableSections.includes('LIVESTREAM')
    const canUseVideo = availableSections.includes('VIDEO')

    return (
      <AccountContext.Provider value={state}>
        <Routes>
          {canUseStreaming && canAccountUseStreaming && (
            <Route caseSensitive path="livestreams/*" Component={Livestreams} />
          )}

          {!canUseStreaming && canAccountUseStreaming && (
            <Route
              caseSensitive
              path="livestreams"
              element={
                <Page>
                  <PageMessage
                    title="Сервис прямых трансляций"
                    control={<Button size="small">Отправить запрос</Button>}>
                    Для активации трансляций обратитесь в поддержку
                  </PageMessage>
                </Page>
              }
            />
          )}

          {!canAccountUseStreaming && (
            <Route
              caseSensitive
              path="livestreams/*"
              Component={NoAccessPage}
            />
          )}

          {!canUseStreaming && (
            <Route
              caseSensitive
              path="livestreams/*"
              element={<Navigate to={getPath('livestreams')} replace />}
            />
          )}

          {canViewStat && (
            <Route caseSensitive path="stat/*" Component={Stat} />
          )}

          {!canViewStat && (
            <Route caseSensitive path="stat" Component={NoAccessPage} />
          )}

          {!canViewStat && (
            <Route
              caseSensitive
              path="stat/*"
              element={<Navigate to={getPath('stat')} replace />}
            />
          )}

          {(
            [
              ['setting/*', SettingsRoutes],
              ['users/*', UsersRoutes]
            ] as const
          ).map(([path, ChildRoutes]) => (
            <Route key={path} caseSensitive path={path} Component={Settings}>
              <Route path="*" Component={ChildRoutes} />
            </Route>
          ))}

          {canUseVideo && <Route caseSensitive path="*" Component={Records} />}

          {!canUseVideo && (
            <Route caseSensitive path="records" Component={NoAccessPage} />
          )}

          {!canUseVideo && (
            <Route
              caseSensitive
              path="records/*"
              element={<Navigate to={getPath('records')} replace />}
            />
          )}
        </Routes>
      </AccountContext.Provider>
    )
  }

  return <Spinner />
}

export default Account
