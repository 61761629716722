import {createContext} from 'react'
import type {AccountAvailableSection} from 'types/Account'
import type {RamblerAccount} from 'utils/account'

export type State = {
  isFetching: boolean
  status: number | null | undefined
  isReady: boolean
  useEncryptor: boolean | null | undefined
  filterIdsRorRemoveVideos: Record<number, true>
  accountsForSharing: {
    list: number[]
    data: Record<number, RamblerAccount>
  }
  createdAt: Date | null
  useRecordsUuid: boolean
  availableSections: AccountAvailableSection[]
}

export const DEFAULT_STATE = {
  isFetching: false,
  status: null,
  isReady: false,
  useEncryptor: null,
  filterIdsRorRemoveVideos: {},
  accountsForSharing: {
    list: [],
    data: {}
  },
  createdAt: null,
  useRecordsUuid: true,
  availableSections: []
}

export default createContext<State>(DEFAULT_STATE)
